<template>
  <div>
    workspace
  </div>
</template>

<script lang="ts" setup>

</script>

<style lang="less">

</style>
